import Link from "next/link";
import { IonIcon } from "@/src/components/Icons";

import rainBarrelImage from "@/public/static/images/rain-barrel-and-flowers-dark-medium-blur.jpg";
import communityImage from "@/public/static/images/community.png";
import emergencyImage from "@/public/static/images/emergency.jpg";
import healthImage from "@/public/static/images/health.jpg";
import localEnergyImage from "@/public/static/images/local-energy.jpg";
import localFoodImage from "@/public/static/images/local-food.jpg";
import preparednessImage from "@/public/static/images/preparedness.png";
import recoveryImage from "@/public/static/images/recovery.jpg";
import waterImage from "@/public/static/images/water-security.png";

export default function HomePage() {
  const projectPanels = [
    {
      id: 5,
      image: localFoodImage.src,
      label: "Food Production + Security",
      filter: "?types=food_production_and_distribution",
    },
    {
      id: 8,
      image: waterImage.src,
      label: "Water Security",
      filter: "?types=water_security",
    },
    {
      id: 4,
      image: localEnergyImage.src,
      label: "Local Energy",
      filter: "?types=local_energy",
    },
    {
      id: 6,
      image: preparednessImage.src,
      label: "Preparedness + Mitigation",
      filter: "?types=preparedness_and_mitigation",
    },
    {
      id: 2,
      image: emergencyImage.src,
      label: "Emergency Response",
      filter: "?types=emergency_response",
    },
    {
      id: 7,
      image: recoveryImage.src,
      label: "Recovery",
      filter: "?types=recovery",
    },
    {
      id: 1,
      image: communityImage.src,
      label: "Community Assistance",
      filter: "?types=community_assistance",
    },
    {
      id: 3,
      image: healthImage.src,
      label: "Health Initiatives",
      filter: "?types=health_initiatives",
    },
  ];

  return (
    <>
      <section
        className="hero is-dark is-bold is-medium"
        style={{
          backgroundImage: `url(${rainBarrelImage.src})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <h1
              className="title is-1 is-size-3-mobile"
              style={{ whiteSpace: "pre-line" }}
            >
              {
                "Thrivespring brings people together to build resilient, prepared, and self-sufficient communities."
              }
            </h1>
          </div>
        </div>
      </section>

      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container has-text-centered is-max-desktop is-hidden-mobile">
            <h1 className="title h-0">
              <span className="icon-text">
                <span>Get connected</span>
                <span className="icon">
                  <IonIcon name="arrow-forward" />
                </span>
                <span>Get organized</span>
                <span className="icon">
                  <IonIcon name="arrow-forward" />
                </span>
                <span>Thrive together</span>
              </span>
            </h1>
          </div>

          <div className="container has-text-centered is-hidden-tablet">
            <h1 className="title">
              <div>Get connected</div>

              <div className="icon is-large">
                <IonIcon name="arrow-down" />
              </div>

              <div>Get organized</div>

              <div className="icon is-large">
                <IonIcon name="arrow-down" />
              </div>

              <div>Thrive together</div>
            </h1>
          </div>
        </div>
      </section>

      <section className="hero is-warning is-medium">
        <div className="container py-6 px-5">
          <div className="columns is-multiline">
            <div className="column is-one-third">
              <p className="has-text-weight-bold has-text-centered is-size-4">
                Our communities need to become more resilient and
                self-sustaining.
              </p>
            </div>

            <div className="column is-one-third">
              <p className="has-text-weight-bold has-text-centered is-size-3">
                Let's make this happen together.
              </p>
            </div>

            <div className="column is-one-third">
              <p className="has-text-weight-bold has-text-centered is-size-4">
                Connect with local community resilience projects focused on:
              </p>
            </div>

            {projectPanels.map((item) => (
              <div key={item.id} className="column is-one-third">
                <Link href={`/community-projects/${item.filter}`}>
                  <div style={{ position: "relative" }}>
                    <figure className="image is-4by3">
                      <img
                        alt={item.label}
                        src={item.image}
                        className="is-cover"
                      />
                    </figure>
                    <div
                      className="has-text-centered"
                      style={{
                        position: "absolute",
                        bottom: 8,
                        width: "90%",
                        marginLeft: "5%",
                        color: "white",
                        background: "rgba(0, 0, 0, 0.5)",
                        border: "white 3px solid",
                        textTransform: "capitalize",
                      }}
                    >
                      <p className="has-text-weight-bold">
                        {item.label.toUpperCase()}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

            <div className="column is-one-third">
              <div className="image is-4by3">
                <div
                  className="notification is-primary"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                  }}
                >
                  <h1
                    className="title is-size-4 is-size-3-widescreen is-size-5-tablet"
                    style={{ color: "white" }}
                  >
                    Help build more robust, adaptive and connected communities
                  </h1>
                  <Link
                    href="/login?next=community-projects"
                    className="button is-maroon"
                  >
                    Get started
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
